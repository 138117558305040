<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" class="pt-5 pb-2" />
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col cols="2" align="right" class="py-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Nro de proceso -->
                  <!-- onkeypress (linea 49) inhabilita letras en firefox -->
                  <v-col class="pb-0">
                    <v-text-field
                      ref="nro-lote"
                      v-model="nroLote"
                      label="N° de lote"
                      outlined
                      dense
                      @keyup="disabledFechas()"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      :rules="
                        rules.positiveNumber.concat([
                          rules.maxLength(nroLote, 8),
                        ])
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Fecha desde -->
                  <v-col class="pb-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          ref="fechaDesde"
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          :disabled="isDisabled"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="fechaDesdeSelected ? rules.validDate : []"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col class="pb-0">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          ref="fechaHasta"
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          :disabled="isDisabled"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="fechaHastaSelected ? rules.validDate : []"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="lotes"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            show-expand
            :expanded.sync="expanded"
            item-key="loteDeudaId"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn color="primary" @click="nuevoLote" class="to-right">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canSend">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="sendLoteDeuda(item)"
                  >
                    {{ sendFileIcon }}
                  </v-icon>
                </template>
                <span>Enviar lote</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="seeLoteDeDeuda(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver lote</span>
              </v-tooltip>
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="editLoteDeuda(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar lote</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteLote(item.loteDeudaId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar lote</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Filtros aplicados:</strong>
                {{ formatExpandable(item.filtrosAplicados) }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import ModalComprobante from "@/components/modules/cuotas/devengamientos/DetalleComprobante.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    ModalComprobante,
    FiltersSelected,
    Ayuda,
  },
  data: (vm) => ({
    searchIcon: enums.icons.SEARCH,
    seeIcon: enums.icons.SEE,
    deleteIcon: enums.icons.DELETE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    editIcon: enums.icons.EDIT,
    calendarIcon: enums.icons.CALENDAR,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    rules: rules,
    sendFileIcon: enums.icons.FILE_SEND,
    title: "Lotes de deudas",
    optionCode: enums.webSiteOptions.LOTES_DEUDA,
    showHelp: false,
    showIcon: true,
    showExpand: false,
    headers: [
      {
        text: "N° de lote",
        align: "start",
        value: "loteDeudaId",
      },
      {
        text: "Período",
        align: "start",
        value: "periodo",
      },
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
      },
      {
        text: "Fecha alta",
        align: "start",
        value: "fechaAlta",
      },
      {
        text: "Usuario alta",
        align: "start",
        value: "usuarioAlta",
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", value: "data-table-expand" },
    ],
    expanded: [],
    lotes: [],
    search: "",
    canDelete: false,
    canCreate: false,
    canSend: false,
    canEdit: false,
    titleDelete: "¿Desea eliminarlo?",
    showFilters: true,
    isFormValid: true,
    menu1: false,
    menu2: false,
    nroLote: null,
    showTable: false,
    isLoading: false,
    isDisabled: false,
    showDeleteModal: false,
    openModalComprobante: false,
    filtersApplied: [],
    fechaDesde: null,
    fechaHasta: null,
    fechaDesdeSelected: null,
    fechaHastaSelected: null,
  }),
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getLotesDeuda: "devengamientos/getLotesDeuda",
      deleteLoteDeuda: "devengamientos/deleteLoteDeuda",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_LOTE_DEUDA:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDIT_LOTE_DEUDA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_LOTE_DEUDA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.ENVIAR_LOTE_DEUDA:
            this.canSend = true;
            break;
          default:
            break;
        }
      });
    },
    async applyFilters() {
      if (
        (this.fechaDesde == null && this.fechaHasta) ||
        (this.fechaHasta == null && this.fechaDesde) ||
        this.fechaDesde == "" ||
        this.fechaHasta == ""
      ) {
        this.setAlert({
          type: "warning",
          message: 'Un campo "fecha" no puede estar vacío',
        });
      } else if (this.nroLote != null || this.fechaDesde <= this.fechaHasta) {
        this.showTable = true;
        this.isLoading = true;
        this.customizeFiltersApplied();
        let params = {};
        if (this.nroLote) {
          params = {
            loteDeudaId: this.nroLote,
          };
        } else {
          if (this.fechaDesdeSelected == "" && this.fechaHastaSelected == "") {
            params = {};
          } else {
            if (
              this.fechaDesdeSelected !== null &&
              this.fechaHastaSelected !== null
            ) {
              params = {
                fechaDesde: `${this.parseDate(
                  this.fechaDesdeSelected
                )} 00:00:00`,
                fechaHasta: `${this.parseDate(
                  this.fechaHastaSelected
                )} 23:59:00`,
              };
            }
            if (
              this.fechaDesdeSelected !== null &&
              this.fechaHastaSelected === null
            ) {
              params = {
                fechaDesde: `${this.parseDate(
                  this.fechaDesdeSelected
                )} 00:00:00`,
              };
            }
            if (
              this.fechaDesdeSelected === null &&
              this.fechaHastaSelected !== null
            ) {
              params = {
                fechaHasta: `${this.parseDate(
                  this.fechaHastaSelected
                )} 23:59:00`,
              };
            }
          }
        }
        try {
          const response = await this.getLotesDeuda(params);
          this.lotes = response;
          this.isLoading = false;
          this.showFilters = false;
          this.nroLote = null;
          this.isDisabled = false;
        } catch {
          this.isLoading = false;
          this.isDisabled = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "La fecha desde no puede ser mayor a la fecha hasta",
        });
      }
    },
    async confirmDelete() {
      const response = await this.deleteLoteDeuda({
        loteDeudaId: this.idToDelete,
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
      }
    },
    nuevoLote() {
      this.$router.push({ name: "CreacionLoteDeuda" });
    },
    deleteLote(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    closeModal() {
      this.dialog = false;
    },
    openModal(id) {
      this.openModalComprobante = true;
      this.id = id;
    },
    disabledFechas() {
      if (this.nroLote) {
        this.isDisabled = true;
        this.fechaDesdeSelected = null;
        this.fechaDesde = null;
        this.fechaHastaSelected = null;
        this.fechaHasta = null;
      } else {
        this.isDisabled = false;
      }
    },
    // metodos de filtros
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected,
        });
      }
      if (this.nroLote) {
        this.filtersApplied.splice(0, 3, {
          key: "nroLote",
          label: "N° de lote",
          model: this.nroLote,
        });
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    sendLoteDeuda(item) {
      this.$router.push({
        name: "EnviarLoteDeuda",
        params: {
          loteDeudaId: item.loteDeudaId,
          nombre: item.nombre,
          entFacId: item.filtrosAplicados.entFac.id,
        },
      });
    },
    seeLoteDeDeuda(item) {
      this.$router.push({
        name: "CreacionLoteDeuda",
        params: { loteDeudaId: item.loteDeudaId, nombre: item.nombre },
      });
    },
    formatExpandable(filtros) {
      const entFac = filtros.entFac.value
        ? "Entidad Facturante: " + filtros.entFac.value.toString()
        : "";
      let convenios;
      if (filtros.convenio.length > 0) {
        const arrayConvenios = [];
        if (filtros.convenio.length >= 2) {
          filtros.convenio.slice(0, 2).forEach((e) => {
            arrayConvenios.push(e.value.trim());
          });
        } else {
          filtros.convenio.forEach((e) => {
            arrayConvenios.push(e.value.trim());
          });
        }
        convenios =
          "Convenios: " +
          arrayConvenios.join(", ").toString() +
          (filtros.convenio.length > 2
            ? " y " + (filtros.convenio.length - 2).toString() + " más."
            : ".");
      } else {
        convenios = "";
      }
      let entFin;
      if (filtros.entidadesFinancieras.length > 0) {
        const arrayEntFin = [];
        if (filtros.entidadesFinancieras.length >= 2) {
          filtros.entidadesFinancieras.slice(0, 2).forEach((e) => {
            arrayEntFin.push(e.value.trim());
          });
        } else {
          filtros.entidadesFinancieras.forEach((e) => {
            arrayEntFin.push(e.value.trim());
          });
        }
        entFin =
          "Entidades Financieras: " +
          arrayEntFin.join(", ").toString() +
          (filtros.entidadesFinancieras.length > 2
            ? " y " +
              (filtros.entidadesFinancieras.length - 2).toString() +
              " más."
            : ".");
      } else {
        entFin = "";
      }
      let formasPago;
      if (filtros.formasDePago.length > 0) {
        const arrayFormasPago = [];
        if (filtros.formasDePago.length >= 2) {
          filtros.formasDePago.slice(0, 2).forEach((e) => {
            arrayFormasPago.push(e.value.trim());
          });
        } else {
          filtros.formasDePago.forEach((e) => {
            arrayFormasPago.push(e.value.trim());
          });
        }
        formasPago =
          "Formas de pago: " +
          arrayFormasPago.join(", ").toString() +
          (filtros.formasDePago.length > 2
            ? " y " + (filtros.formasDePago.length - 2).toString() + " más."
            : ".");
      } else {
        formasPago = "";
      }
      let tiposComprobantes;
      if (filtros.tiposDeComprobantes.length > 0) {
        const arrayTipoComprobantes = [];
        if (filtros.tiposDeComprobantes.length >= 2) {
          filtros.tiposDeComprobantes.slice(0, 2).forEach((e) => {
            arrayTipoComprobantes.push(e.value.trim());
          });
        } else {
          filtros.tiposDeComprobantes.forEach((e) => {
            arrayTipoComprobantes.push(e.value.trim());
          });
        }
        tiposComprobantes =
          "Tipos de comprobantes: " +
          arrayTipoComprobantes.join(", ").toString() +
          (filtros.tiposDeComprobantes.length > 2
            ? " y " +
              (filtros.tiposDeComprobantes.length - 2).toString() +
              " más."
            : ".");
      } else {
        tiposComprobantes = "";
      }
      const periodoDesde = filtros.periodoDesde
        ? "Periodo desde: " + filtros.periodoDesde + "."
        : "";
      const periodoHasta = filtros.periodoHasta
        ? "Periodo hasta: " + filtros.periodoHasta + "."
        : "";
      const comprobantesDesde = filtros.fechaComprobanteDesde
        ? "Fecha comprobante desde: " + filtros.fechaComprobanteDesde + "."
        : "";
      const comprobantesHasta = filtros.fechaComprobanteHasta
        ? "Fecha comprobante hasta: " + filtros.fechaComprobanteHasta + "."
        : "";
      const comprobantesIncluidos = filtros.incluirComprobantesLotes
        ? "Comprobantes incluidos en otro lote: Sí"
        : "Comprobantes incluidos en otro lote: No";
      const filtrosAplicados =
        entFac +
        " " +
        convenios +
        " " +
        entFin +
        " " +
        formasPago +
        " " +
        tiposComprobantes +
        " " +
        comprobantesDesde +
        " " +
        comprobantesHasta +
        " " +
        periodoDesde +
        " " +
        periodoHasta +
        " " +
        comprobantesIncluidos;
      return filtrosAplicados;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    editLoteDeuda(item) {
      this.$router.push({
        name: "CreacionLoteDeuda",
        params: {
          loteDeudaId: item.loteDeudaId,
          nombre: item.nombre,
          fromEdit: true,
        },
      });
    },
  },
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
